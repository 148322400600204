import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import * as Icon from 'react-bootstrap-icons';

const Home = (props) => {

  if (!localStorage.getItem('accessToken')) {
    window.location.href = "/login"
  }

  function realFileSize(fileSize) {
    const units = ['KiB', 'MiB', 'GiB']
    var selectedUnit = null
    var realFileSize = fileSize

    for (let i = 0; i < units.length; i++) {
      if (realFileSize >= 1024) {
        realFileSize = realFileSize / 1024
        selectedUnit = units[i]
      }
    }

    return realFileSize.toFixed(2).toString() + ' ' + selectedUnit
  }

  const [backendData, setBackendData] = useState([{}])
  const [showRefresh, setShowRefresh] = useState(false)
  const [showMeta, setShowMeta] = useState(false)
  const [refreshGL, updateRefresh] = useState([{}])
  const [updMeta, updateMetadata] = useState([{}])

  const handleCloseRefresh = () => setShowRefresh(false);
  const handleCloseMetadata = () => setShowMeta(false);
  const handleRefreshList = () => {
    setShowRefresh(true)
    fetch("https://api.nsplanade.fr/v1/refresh/gameList?type=all&token=" + localStorage.getItem('accessToken'))
      .then(response => response.json())
      .then(responseJson => {
        const array = []
        array.push(responseJson)
        updateRefresh(array)
      })
  };
  const handleMetadataDownload = () => {
    const array = []
    setShowMeta(true)
    fetch("https://api.nsplanade.fr/v1/refresh/downloadMetadata?type=titles&token=" + localStorage.getItem('accessToken'))
      .then(response => response.json())
      .then(responseJson => {
        array.push(responseJson)
      })
      .then(
        fetch("https://api.nsplanade.fr/v1/refresh/downloadMetadata?type=versions&token=" + localStorage.getItem('accessToken'))
          .then(response => response.json())
          .then(responseJson => {
            array.push(responseJson)
          })
          .then(
            fetch("https://api.nsplanade.fr/v1/refresh/downloadMetadata?type=lang&token=" + localStorage.getItem('accessToken'))
              .then(response => response.json())
              .then(responseJson => {
                array.push(responseJson)
                updateMetadata(array)
              })
          )
      )
  }
  const handleReload = () => window.location.reload();

  var showReloadMeta = false

  useEffect(() => {
    fetch("https://api.nsplanade.fr/v1/get/gameList?type=web&token=" + localStorage.getItem('accessToken'))
      .then(response => response.json())
      .then(
        data => {
          var array = [];
          Object.entries(data).map(([key, value]) => {
            array.push(value)
            array.sort((a, b) => (a.game.title.localeCompare(b.game.title, 'fr')));
          })
          setBackendData(array)
        }
      )
  }, [])

  return (
    <main>
      <Modal show={showRefresh} onHide={handleCloseRefresh} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>Refresh Game List</Modal.Title>
        </Modal.Header>
        {refreshGL.map((responseData) => (
          <div>
            <Modal.Body>
              {responseData.status == "ok" ? responseData.message : <Row><Col style={{ textAlign: 'center' }}><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></Col></Row>}
            </Modal.Body>
            <Modal.Footer>
              {responseData.status == "ok" &&
                <Button variant="secondary" onClick={handleReload}>
                  Reload Page
                </Button>
              }
            </Modal.Footer>
          </div>
        ))}
      </Modal>
      <Modal show={showMeta} onHide={handleCloseMetadata} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>Download Metadata</Modal.Title>
        </Modal.Header>
        {updMeta.map((responseData) => (
          <div>
            <Modal.Body>
              {responseData.status == "ok" ? responseData.message : <Row><Col style={{ textAlign: 'center' }}><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></Col></Row>}
            </Modal.Body>
            <Modal.Footer>
              {responseData.status == "ok" && 
                <Button variant="secondary" onClick={handleReload}>
                  Reload Page
                </Button>
              }
            </Modal.Footer>
          </div>
        ))}
      </Modal>
      <header>
        <Navbar fixed='top' expand="lg" className="navbar-expand-md navbar-dark bg-dark shadow-sm">
          <Container>
            <Navbar.Brand className='d-flex align-items-center' href="#">
              <Icon.Controller className='brandLogo' id='brandLogo'></Icon.Controller>
              <strong>&nbsp;nSplanade</strong>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className='justify-content-end mt-2 mt-md-0' id="basic-navbar-nav">
              <Button className='mx-0 mx-md-1 my-2 my-md-0 text-light' onClick={handleRefreshList}>
                <Icon.ArrowRepeat></Icon.ArrowRepeat>
                <span class="d-inline-block d-md-none"> Refresh List</span>
              </Button>
              <Button className='mx-0 mx-md-1 my-2 my-md-0 text-light' onClick={handleMetadataDownload}>
                <Icon.CloudDownload></Icon.CloudDownload>
                <span class="d-inline-block d-md-none"> Update Metadata</span>
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <div class='py-3 bg-ligth' style={{ marginTop: '4rem' }}>
        <Container>
          {(typeof backendData === 'undefined') ? (
            <p>Loading...</p>
          ) : (
            backendData.map((item, i) => (
              (typeof item['game'] != 'undefined') ? (
                <Row className='gx-2 mb-4'>
                  <Col className='col-2 d-none d-md-block'>
                    <Card className='px-0 shadow-sm fill cardThumb'>
                      {(typeof item['icon_url'] != 'undefined') ? (<img class='img-fluid rounded d-block lazy' src={item['icon_url']} alt='icon'></img>) : (<p></p>)}
                    </Card>
                  </Col>
                  <Col className='col-12 col-md-10'>
                    <Card className='shadow-sm'>
                      <div class='cardBanner fill rounded-3'>
                        {(typeof item['banner_url'] != 'undefined') ? (<img class='img-fluid lazy' src={item['banner_url']} alt='banner' width='5%' height='5%'></img>) : (<p></p>)}
                      </div>
                      <Card.Body className='rounded cardBody'>
                        <Card.Title>
                          <strong>{item['game']['title']}</strong>
                        </Card.Title>
                        <Card.Text>
                          <p class="small noWrap">{item['game']['description']}</p>
                          <p class="small">
                            <strong>Release Date: </strong>
                            <span class="text-muted">{item['game']['release_date']}</span>
                          </p>
                          <p class="small">
                            <strong>Latest Version:</strong>
                            <span class={(parseInt(item['game']['last_update'], 10) > (item['update'] ? parseInt(item['update']['version'].replace('v', ''), 10) : 0)) ? "badge bg-danger mx-md-1" : "badge bg-success mx-md-1"}>v{item['game']['last_update']}</span>
                            <span class="text-muted">({item['game']['last_update_date']})</span>
                          </p>
                          <Accordion>
                            <Accordion.Item>
                              <p class='my-1 accordion-button collapsed' style={{ display: 'block' }}>
                                <strong>Base Game</strong>
                                <Badge bg='success' className='mx-md-1'>{item['game']['file_type'].toUpperCase()}</Badge>
                                <span class='float-end'>
                                  <Badge bg="secondary">{realFileSize(item['game']['file_size'])}</Badge>
                                  <a href={item['game']['file_name'].replace('FULLURI', 'https://dl.nsplanade.fr')} title="" class="btn btn-sm bg-primary text-light mx-md-1" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-original-title="Download">
                                    <Icon.Download></Icon.Download>
                                  </a>
                                </span>
                              </p>
                            </Accordion.Item>
                            {typeof item['update'] != 'undefined' &&
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <p class='my-1'>
                                    <strong>Updates</strong>
                                    <span class="badge bg-secondary mx-md-1">1</span>
                                  </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup style={{ display: 'block' }}>
                                    <ListGroup.Item>
                                      {item['update']['version']}
                                      <small class="text-muted"> (#{item['update']['number']}, {item['update']['update_date']})</small>
                                      <span class="float-end">
                                        <span class="badge bg-secondary">{realFileSize(item['update']['file_size'])}</span>
                                        <a href={item['update']['file_name'].replace('FULLURI', 'https://dl.nsplanade.fr')} title="" class="btn btn-sm bg-primary text-light mx-md-1" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-original-title="Download">
                                          <Icon.Download></Icon.Download>
                                        </a>
                                      </span>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            }
                            {item['dlc'].length > 0 &&
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                  <p class='my-1 contentListTrigger'>
                                    <strong>DLC</strong>
                                    <span class="badge bg-secondary mx-md-1">{item['dlc'].length}</span>
                                  </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup style={{ display: 'block' }}>
                                    {item['dlc'].map((dlcItem, j) => (
                                      <ListGroup.Item>
                                        {dlcItem.dlc_title}
                                        <span class="float-end">
                                          <span class="badge bg-secondary">{realFileSize(dlcItem['file_size'])}</span>
                                          <a href={dlcItem['file_name'].replace('FULLURI', 'https://dl.nsplanade.fr')} title="" class="btn btn-sm bg-primary text-light mx-md-1" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-original-title="Download">
                                            <Icon.Download></Icon.Download>
                                          </a>
                                        </span>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            }
                          </Accordion>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : (<Row><Col style={{ textAlign: 'center' }}><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></Col></Row>)
            ))
          )}
        </Container>
      </div>
      <footer class="text-muted py-5">
        <div class="container">
          <p class="float-end mb-1">
            <a href="#">Back to top</a>
          </p>
          <p class="mb-1">nSplanade v<span id="version">0.0.3</span></p>
        </div>
      </footer>
    </main>
  );
}

export default Home;
